@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);
.user {
    padding: 12px 40px;
    background: #fff;
    border: transparent;
    /* text-align: center; */
}
@media (max-width:576px) {
    .user {
        padding: 3px 15px;
    }
}

.user-profile {
    position: relative;
    z-index: 1;
    border-radius: 50%;
    /* width: auto;
    height: auto; */
    width: 66%;
    height: auto;
    max-height: 200px;

}

.user-profile-col {
    display: flex;
    justify-content: left;
    align-items: center;
}

.user-profile .profile-image {
    outline: 7px solid #fff;
    /* left: 9px; */
    /* top: 9px; */
    width: 100%;
    height: auto;
    max-height: 150px;
    max-width: 150px;
}

.user-profile::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: " ";
    margin: -1px;
    bottom: 0;
    border-radius: inherit;
    z-index: -1;
    /* background: linear-gradient(64.51deg, #FFC500 12.72%, #FC006D 51.43%, #D300C5 87.42%); */
}

.profile-detail .ant-statistic-title,
.profile-detail .ant-statistic-content {
    font-size: clamp(12px, 2.5vw, 16px);
    color: #5D7285;
}

.profile-detail .ant-statistic-title {
    font-weight: 600;
    color: #262626;
}

.profile-name {
    font-size: clamp(14px, 3vw, 20px);
    font-weight: 500;
    line-height: 1.5em;
    margin: 16px 0;
}

.profile-desc {
    font-size: clamp(12px, 2vw, 14px);
}

.profile-title {
    color: #262626;
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
}

.profile-about,
.profile-follower {
    color: #262626;
    margin: 0;
    font-weight: 400;
}

.userInfoMainRow {
    /* column-gap: 8%; */
    /* justify-content: center; */
}
/* @media (max-width:576) {
    .userInfoMainRow {
        column-gap: 0px;
    }
} */
/* .profile-detail-row .ant-row {
    column-gap: 12px;
} */

/* @media (max-width: 768px) {
    .profile-detail-row .ant-row {
        column-gap: 12px;
    }
} */

/* Center the Card on the Page */
/* .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height 
  } */

/* Additional styles to ensure content alignment */
/* .userInfoMainRow {
    justify-content: center; /* Aligns the entire row content to center horizontally 
  } */

/* .user-profile, 
  .profile-name,
  .profile-detail,
  .profile-desc {
    text-align: center; /* Aligns text inside these elements to the center 
  } */

/* If using Flexbox for the card content alignment */
/* .user-profile {
    display: flex;
    justify-content: center;
  } */
body.dark-mode .user {
    /* padding: 12px 40px; */
    background: #1e1e1e;
    /* border: transparent; */
}

/* @media (max-width:576px) {
    .user {
        padding: 3px 15px;
    }
} */

/* .user-profile {
    position: relative;
    z-index: 1;
    border-radius: 50%;
    width: auto;
    height: auto;
} */

body.dark-mode h2 {
    color: white;
}

/* .user-profile-col {
    display: flex;
    justify-content: left;
    align-items: center;
} */

/* .user-profile .profile-image {
    outline: 7px solid #fff;
    width: 66%;
    height: auto;
} */

/* .user-profile::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: " ";
    margin: -1px;
    bottom: 0;
    border-radius: inherit;
    z-index: -1;
} */

body.dark-mode .profile-detail .ant-statistic-title,
body.dark-mode .profile-detail .ant-statistic-content {
    /* font-size: clamp(12px, 2.5vw, 16px); */
    color: #a0a0a0;

}

body.dark-mode .profile-detail .ant-statistic-title {
    /* font-weight: 600; */
    color: #e0e0e0;
}

/* .profile-name {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5em;
    margin: 16px 0;
} */

/* .profile-desc {
    font-size: clamp(12px, 2vw, 14px);
} */

body.dark-mode .profile-title {
    color: #ffffff;
    /* margin: 0;
    font-weight: 600;
    text-transform: capitalize; */
}

body.dark-mode .profile-about,
body.dark-mode .profile-follower {
    color: #e0e0e0;
    /* margin: 0;
    font-weight: 400; */
}

/* .userInfoMainRow {
    column-gap: 8%;

} */


/* Center the Card on the Page */
/* .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height 
  } */

/* Additional styles to ensure content alignment */
/* .userInfoMainRow {
    justify-content: center; /* Aligns the entire row content to center horizontally 
  } */

/* .user-profile, 
  .profile-name,
  .profile-detail,
  .profile-desc {
    text-align: center; /* Aligns text inside these elements to the center 
  } */

/* If using Flexbox for the card content alignment */
/* .user-profile {
    display: flex;
    justify-content: center;
  } */
.menu-vertical .ant-menu-item-selected{
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  /* background-color: #1e1e1e; */
}
/* .menu-vertical .ant-menu-item:hover {
  color: 'white' !important
} */
.menu-vertical {
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px;
  width: 100%;
  /* font-size: 1vw; */
  /* background-color: #1e1e1e; */

}
/* .ant-layout-sider {
  width: 240px;
} */

.sidebar .ant-layout-sider-trigger{
  border-radius: 4px;
  /* background-color: #1e1e1e; */
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /* z-index: 100;
  overflow: auto; */
  height: 100vh; /* Full height */
}

.content-layout {
  /* margin-left: 200px; Adjust this value to match the Sider's width when not collapsed */
  transition: margin-left 0.2s;
}

.ant-drawer-content {
  font-family: 'Montserrat', sans-serif;
}
/* .ant-layout-sider-trigger:where(.css-dev-only-do-not-override-o2upwn).ant-layout .ant-layout-sider-trigger {
  background-color: white;
} */
/* .ant-menu-item {
    color: #fff !important;
  }
  
  .ant-menu-submenu-title {
    color: #fff !important;
  }
  
  .ant-menu-submenu-open > .ant-menu-submenu-title,
  .ant-menu-submenu-active > .ant-menu-submenu-title,
  .ant-menu-submenu-title:hover {
    color: #fff !important;
  }
  
  .ant-menu-item-only-child {
    color: #fff !important;
  } */
body.dark-mode .menu-vertical .ant-menu-item-selected{
    /* font-weight: 600;
    font-family: 'Montserrat', sans-serif; */
    background-color: #1e1e1e;
  }
  /* .menu-vertical .ant-menu-item:hover {
    color: 'white' !important
  } */
  body.dark-mode .menu-vertical {
    /* font-family: 'Montserrat', sans-serif; */
    background-color: #2d2d2d;
    /* border-radius: 4px; */
    /* width: 100%; */
  }
  /* .sidebar {
    background-color: #1e1e1e;
  } */
  body.dark-mode .sidebar .ant-layout-sider-children {
    background-color: #1e1e1e;
    /* border-radius: 4px; */
  }
  body.dark-mode .sidebar .ant-layout-sider-trigger{
    /* border-radius: 4px; */
    background-color: #1e1e1e;
  }

  /* UserLayout.css */
  body.dark-mode .sidebar {
  /* position: fixed;
  top: 0;
  left: 0;
  bottom: 0; */
  /* border-radius: 4px; */
  /* z-index: 100;
  overflow: auto; */
   /* Full height */
  /* background: none; */
  background-color: #1e1e1e;
}
/* .content-layout {
  transition: margin-left 0.2s;
} */
/* .ant-drawer-content {
  font-family: 'Montserrat', sans-serif;
} */

/* .content-layout.collapsed {
  margin-left: 80px; 
} */

  /* .ant-layout-sider-trigger:where(.css-dev-only-do-not-override-o2upwn).ant-layout .ant-layout-sider-trigger {
    background-color: white;
  } */
  /* .ant-menu-item {
      color: #fff !important;
    }
    
    .ant-menu-submenu-title {
      color: #fff !important;
    }
    
    .ant-menu-submenu-open > .ant-menu-submenu-title,
    .ant-menu-submenu-active > .ant-menu-submenu-title,
    .ant-menu-submenu-title:hover {
      color: #fff !important;
    }
    
    .ant-menu-item-only-child {
      color: #fff !important;
    } */
.tag-card{
  /* position: relative; */
  /* max-width: 250px; */
  /* height: 100%; */
  border:4px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  cursor: pointer;
}
.tag-card img{width: 100%; max-height: 220px; min-height: 220px;}
.insights-btn {
  position: absolute;
  bottom: 15px;
  right: 10px;
  display: none; /* Initially hidden */
  opacity: 0;
  transition: opacity 0.3s ease;
}
.tag-card:hover .insights-btn {
  display: block;
  opacity: 1;
}

.tag-icon{
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: start;
    color: rgb(255, 255, 255);
    width: 90px;
    position: absolute;
    height: 90px;
    border-radius: 6px;
}
.tag-text{
  transform: rotate(312deg);
  position: relative;
  top: -15%;
  left: 15%;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.tag-product{
  max-width: 130px;
  position: relative;
  cursor: pointer;
}
.tag-product-icon{
  display: inline-block;
  position: absolute;
  right: 4px;
  top: 4px;
}
.tag-product-img{
  width: 100%;
  height: 80px;
}
.tag-product-name{
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
}
.product-select-icon{
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 10px;
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.listView-mainTitle {
    margin: 12px 0px;
}

.listView-mainSearch {
    margin: 12px 0px;
    font-size: 16px;
}

.listView-moreBtn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.listView-itemTitle {
    margin: 3px 0px !important;
}

.listView-avatarDiv {
    padding: 2px;
    background: linear-gradient(45deg, #f4b301, #f4b301, #ffd700, #ffcc00);
    border-radius: 50%;
}
.login-image{
    max-width: 300px;
}
/* Container */
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f5f7; /* Matches the dashboard background */
    font-family: 'Arial', sans-serif;
    padding: 0 20px;
  }
  
  /* Error Box */
  .error-box {
    background-color: #ffffff; /* White background for cards */
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  /* Error Code */
  .error-code {
    font-size: 5rem;
    font-weight: 700;
    color: #2575fc; /* Matching the blue color of your theme */
    margin: 0;
  }
  
  /* Error Message */
  .error-message {
    font-size: 1.25rem;
    margin: 20px 0;
    color: #6b7280; /* Subtle gray text */
  }
  
  /* Link to Home */
  .home-link {
    display: inline-block;
    padding: 12px 24px;
    cursor: pointer;
    background-color: #F4B301;
    color: white;
    text-decoration: none;
    border-radius: 25px;
    font-size: 1rem;
    transition: background 0.3s ease-in-out;
  }
  
  .home-link:hover {
    background-color: #8b3ffb; /* Lighter hover effect */
  }
body .ant-layout {
  font-family: 'Montserrat', sans-serif ;
  /* background: #fff; */
}
.text-primary{
  color: #F4B301 !important;
}
.text-white{
  color: #fff;
}
.w-full{width: 100%;}
.text-center{text-align: center;}
.text-left{text-align: left;}
.text-right{text-align: right;}
.m-0{
  margin: 0;
}
.mt-0{
  margin-top: 0;
}
.mb-0{
  margin-bottom: 0;
}
.mt-6{
  margin-top: 1.5rem;
}
.btn-size{
  width: 260px;
}
.text-gray{color:#8E8E8E}
.text-xs{font-size: 12px;}
.text-md{font-size: 18px;}
.text-black{color: #262626;}
.font-semibold{font-weight: 600;}
.text-pink{
  color: #F31260
  ;
}
.user-dashboard{
  padding: 24px 30px;
  background: #fff;
  min-height: 360px;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
}
.border-primary{
  border-color: #F4B301;
}
@media (max-width:576px) {
  .user-dashboard {
    padding: 0px 15px; 
  }
}

/*ant components*/

.ant-typography,
.ant-btn,
.ant-card,
.ant-modal,
.ant-table,
.ant-menu,
.ant-input, 
.ant-select,
.ant-drawer
.ant-row,
.ant-form,
.ant-col,
.ant-list,
.ant-table-container,
.ant-statistic-content-value { 
  font-family: 'Montserrat', sans-serif;
}
body.dark-mode, body.dark-mode .ant-layout {
  /* font-family: 'Montserrat', sans-serif ; */
  background: #1e1e1e;
}
body.dark-mode .text-primary{
  color: #e0e0e0 !important;
}
/* body.dark-mode .text-white{
  color: #fff;
} */
/* .w-full{width: 100%;}
.text-center{text-align: center;}
.text-left{text-align: left;}
.text-right{text-align: right;}
.m-0{
  margin: 0;
}
.mt-0{
  margin-top: 0;
}
.mb-0{
  margin-bottom: 0;
}
.mt-6{
  margin-top: 1.5rem;
}
.btn-size{
  width: 260px;
} */
body.dark-mode .text-gray{color:#ffffff}
/* .text-xs{font-size: 12px;} */
/* .text-md{font-size: 18px;} */
body.dark-mode .text-black{color: #ffffff;}
/* .font-semibold{font-weight: 600;} */
/* .text-pink{
  color: #F31260
  ;
} */
body.dark-mode .user-dashboard{
  /* padding: 24px 30px; */
  background: #1e1e1e;
  /* min-height: 360px; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
}
/* @media (max-width:576px) {
  .user-dashboard {
    padding: 0px 15px;
  }
} */
/* .border-primary{
  border-color: #F4B301;
} */

/*ant components*/

/* .ant-typography,
.ant-btn,
.ant-card,
.ant-modal,
.ant-table,
.ant-menu,
.ant-input, 
.ant-select,
.ant-drawer
.ant-row,
.ant-form,
.ant-col,
.ant-list,
.ant-table-container,
.ant-statistic-content-value { 
  font-family: 'Montserrat', sans-serif;
} */

/* .ant-form-item-required {
    color: white !important;
} */
body.dark-mode .ant-table-container {
    background-color: #2d2d2d;
    color: white;
}
.ant-table-tbody > tr:hover > td {
  background-color: inherit !important; /* Or your specific color */
}
body.dark-mode .ant-pagination-prev .ant-pagination-item-link,
body.dark-mode .ant-pagination-next .ant-pagination-item-link {
  color: white !important; /* Change arrow color to white */
  border-color: white !important; /* Change arrow border color to white */
  background-color: transparent; /* Optional: Make the background transparent */
}
body.dark-mode .ant-modal .ant-form-item-label > label {
  color: black; /* Color inside Modal */
}
  

/* .ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  color: white !important; /* Maintain arrow color on hover */
  /*border-color: white !important; /* Maintain border color on hover 
} */

