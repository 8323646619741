/* Container */
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f5f7; /* Matches the dashboard background */
    font-family: 'Arial', sans-serif;
    padding: 0 20px;
  }
  
  /* Error Box */
  .error-box {
    background-color: #ffffff; /* White background for cards */
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  /* Error Code */
  .error-code {
    font-size: 5rem;
    font-weight: 700;
    color: #2575fc; /* Matching the blue color of your theme */
    margin: 0;
  }
  
  /* Error Message */
  .error-message {
    font-size: 1.25rem;
    margin: 20px 0;
    color: #6b7280; /* Subtle gray text */
  }
  
  /* Link to Home */
  .home-link {
    display: inline-block;
    padding: 12px 24px;
    cursor: pointer;
    background-color: #F4B301;
    color: white;
    text-decoration: none;
    border-radius: 25px;
    font-size: 1rem;
    transition: background 0.3s ease-in-out;
  }
  
  .home-link:hover {
    background-color: #8b3ffb; /* Lighter hover effect */
  }