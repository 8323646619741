.user {
    padding: 12px 40px;
    background: #fff;
    border: transparent;
    /* text-align: center; */
}
@media (max-width:576px) {
    .user {
        padding: 3px 15px;
    }
}

.user-profile {
    position: relative;
    z-index: 1;
    border-radius: 50%;
    /* width: auto;
    height: auto; */
    width: 66%;
    height: auto;
    max-height: 200px;

}

.user-profile-col {
    display: flex;
    justify-content: left;
    align-items: center;
}

.user-profile .profile-image {
    outline: 7px solid #fff;
    /* left: 9px; */
    /* top: 9px; */
    width: 100%;
    height: auto;
    max-height: 150px;
    max-width: 150px;
}

.user-profile::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: " ";
    margin: -1px;
    bottom: 0;
    border-radius: inherit;
    z-index: -1;
    /* background: linear-gradient(64.51deg, #FFC500 12.72%, #FC006D 51.43%, #D300C5 87.42%); */
}

.profile-detail .ant-statistic-title,
.profile-detail .ant-statistic-content {
    font-size: clamp(12px, 2.5vw, 16px);
    color: #5D7285;
}

.profile-detail .ant-statistic-title {
    font-weight: 600;
    color: #262626;
}

.profile-name {
    font-size: clamp(14px, 3vw, 20px);
    font-weight: 500;
    line-height: 1.5em;
    margin: 16px 0;
}

.profile-desc {
    font-size: clamp(12px, 2vw, 14px);
}

.profile-title {
    color: #262626;
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
}

.profile-about,
.profile-follower {
    color: #262626;
    margin: 0;
    font-weight: 400;
}

.userInfoMainRow {
    /* column-gap: 8%; */
    /* justify-content: center; */
}
/* @media (max-width:576) {
    .userInfoMainRow {
        column-gap: 0px;
    }
} */
/* .profile-detail-row .ant-row {
    column-gap: 12px;
} */

/* @media (max-width: 768px) {
    .profile-detail-row .ant-row {
        column-gap: 12px;
    }
} */

/* Center the Card on the Page */
/* .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height 
  } */

/* Additional styles to ensure content alignment */
/* .userInfoMainRow {
    justify-content: center; /* Aligns the entire row content to center horizontally 
  } */

/* .user-profile, 
  .profile-name,
  .profile-detail,
  .profile-desc {
    text-align: center; /* Aligns text inside these elements to the center 
  } */

/* If using Flexbox for the card content alignment */
/* .user-profile {
    display: flex;
    justify-content: center;
  } */